import { Button, Icon, Spin } from 'antd';
import React, {
  useState,
  useEffect,
  // useMemo,
  useRef,
  useCallback
} from 'react';
import intl from 'react-intl-universal';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';

import checkDsersExtension from 'utils/checkDsersExtension';
import { getChromeExtUrl } from 'utils/utils';
import checkVisibleSupport from 'utils/checkDocumentVisible';
import styles from './InstallChromePlugin.less';
import RegisterProcess from './RegisterProcess';
import logo from 'assets/svg/logo.svg';
import downloadIcon from '../../assets/svg/download.svg';
import logoWhite from '../../assets/logo/logo_white.png';
import BindWrapper from './BindWrapper';
import { setUserInstalledExt } from 'common/checkUserForInstallExt';

const { hidden, visibilityChange } = checkVisibleSupport();

const InstallChromePlugin = ({ userInfo, dispatch }) => {
  // console.log(userInfo);
  const [loading, setLoading] = useState(false);
  // 初始化时插件是否未安装
  const [initCheck, setInitCheck] = useState(false);
  // 插件是否已安装
  const installResultRef = useRef(false);

  const clickInstallHandler = () => {
    const url = getChromeExtUrl();
    window.open(url, 'blank');
    setInitCheck(true);
    setLoading(true);
  };

  const handleLogout = () => {
    if (window.localStorage.getItem('online_notice_ioss')) {
      window.localStorage.removeItem('online_notice_ioss');
    }
    dispatch({
      type: 'login/logout'
    });
  };

  // 执行初始化检查 如果已安装 则直接跳转
  useEffect(() => {
    if (!userInfo) {
      return;
    }
    const initCheckExt = async () => {
      setLoading(true);
      try {
        await checkDsersExtension();
        setUserInstalledExt(userInfo.user_id);
        installResultRef.current = true;
        setTimeout(() => {
          dispatch(routerRedux.push('/'));
        }, 500);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    initCheckExt();
  }, [userInfo]);

  useEffect(() => {
    // 初始化未安装时才注册定时器
    if (!initCheck) {
      return () => {};
    }
    // 防止不必要的定时器后续执行 当flag为true时后续逻辑不再执行
    let flag = false;
    // 定时检查插件是否安装完成
    const intervalTag = setInterval(async () => {
      if (flag) {
        return;
      }
      try {
        await checkDsersExtension();
        if (!flag) {
          flag = true;
          setUserInstalledExt(userInfo.user_id);
          installResultRef.current = true;
          if (!visibilityChange) {
            dispatch(routerRedux.push('/'));
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 500);

    return () => {
      flag = true;
      clearInterval(intervalTag);
    };
  }, [initCheck]);

  useEffect(() => {
    // 当页面支持检测页面可见性并且需要安装插件时再注册事件
    let checkInterval = null;
    let tag = false;
    if (visibilityChange && initCheck) {
      checkInterval = setInterval(() => {
        console.log('check');
        if (tag) {
          return;
        }
        if (installResultRef.current && !document[hidden]) {
          tag = true;
          setLoading(false);
          setTimeout(() => {
            dispatch(routerRedux.push('/'));
          }, 500);
        }
      }, 500);
    }
    return () => {
      if (visibilityChange && initCheck) {
        clearInterval(checkInterval);
      }
    };
  }, [dispatch, initCheck]);

  if (!userInfo) {
    return <Spin loading />;
  }

  return (
    <BindWrapper>
      <div className={styles.content}>
        <img className={styles.logo} width="117" src={logo} />
        <RegisterProcess />
        <div className={styles.field}>
          <p className={styles.fieldTitle}>
            {intl.get('linkStore.install_plugin_title')}
          </p>
        </div>
        <div className={styles.btnLine}>
          <Button
            loading={loading}
            type="primary"
            disabled={installResultRef.current}
            onClick={clickInstallHandler}
          >
            <span className={styles.downloadBtn}>
              {installResultRef.current ? (
                <Icon className={styles.installed} type="check-circle" />
              ) : (
                <>
                  <img src={downloadIcon} />
                  <img src={logoWhite} />
                </>
              )}
            </span>
          </Button>
          <Button onClick={handleLogout} type="default">
            {intl.get('public.log_out')}
          </Button>
        </div>
      </div>
    </BindWrapper>
  );
};

export default connect(({ login }) => {
  return {
    userInfo: login.userInfo
  };
})(React.memo(InstallChromePlugin));
